<template>
  <BannerStyle1 :list="bannerList" :position="6"></BannerStyle1>
  <div class="usually-box">
    <section class="introduce top-box">
      <h3 class="title">产品简介</h3>
      <p class="descript">基于可信区块链技术和物联网技术，解决了溯源信息的真实性问题，驱动业务快速增长，提供从品牌到商品的精细化溯源管理服务，包括
        品牌商管理、商品管理、溯源管理，适用于全领域溯源场景，包括但不限于食品药品、农产品、酒、茶、工艺品、陶瓷、钻石、美妆等。</p>
    </section>
    <section class="superiority">
      <h3 class="title">产品优势</h3>
      <ul class="flex-between">
        <li class="shadow-hover" v-for="(item, index) in superiorityList" :key="index">
          <img :src="item.img" alt="">
          <h3>{{item.name}}</h3>
          <p>{{item.desc}}</p>
        </li>
      </ul>
    </section>
    <section class="framework">
      <h3 class="title">适用行业</h3>
      <ul class="flex-between">
        <li v-for="(item, index) in frameworkList">
          <img class="back-img" :src="item.imgBack" alt="">
          <div class="coverd">
            <img :src="item.img" alt="">
            <h3>{{item.name}}</h3>
            <div class="english">
              {{item.english}}
            </div>
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>
<script setup>
import { ref, reactive, computed } from 'vue'
const bannerList = [
  {
    img: require("@/assets/product/source/banner.png"),
    title: '产品溯源 SaaS 系统',
    desc: '系统可信、数据可信、用户可信的一站式产品溯源解决方案',
    btnText: '预约演示',
    url: '/post/contact',
  }
]

const superiorityList = [
  {
    name: '自定义模板',
    img: require("@/assets/product/source/superiority1.png"),
    desc: '提供各类溯源模板，用户可以自定义各行各业的溯源信息，适配性更强；提供API接口，可以定制专属溯源页面。',
  },
  {
    name: '多级批次管理',
    img: require("@/assets/product/source/superiority2.png"),
    desc: '提供多级批次管理，适合各类商品管理模式，可应用用于不同规格的包装、商品类型等。',
  },
  {
    name: '多重防伪技术',
    img: require("@/assets/product/source/superiority3.png"),
    desc: '通过RFID和刮码防伪等技术，与区块链溯源相结合，实现商品防伪可信、生产溯源可信，构建全方位的防伪溯源解决方案。',
  },
  {
    name: '标准API接口',
    img: require("@/assets/product/source/superiority4.png"),
    desc: '开放API接口便于企业将自有应用体系溯源系统无缝链接，可以实现企业中台+区块链溯源、商城+区块链溯源等全方位生态体系。',
  },
]
const frameworkList = [
  {
    name: '制造业',
    english: 'Manufacturing',
    img: require('@/assets/product/source/sort1.png'),
    imgBack: require('@/assets/product/source/sort-back1.png')
  },
  {
    name: '种植业',
    english: 'Planting',
    img: require('@/assets/product/source/sort2.png'),
    imgBack: require('@/assets/product/source/sort-back2.png')
  },
  {
    name: '养殖业',
    english: 'Aquaculture',
    img: require('@/assets/product/source/sort3.png'),
    imgBack: require('@/assets/product/source/sort-back3.png')
  },
  {
    name: '奢侈品',
    english: 'Luxuries',
    img: require('@/assets/product/source/sort4.png'),
    imgBack: require('@/assets/product/source/sort-back4.png')
  },
  {
    name: '电子产品',
    english: 'Manufacturing',
    img: require('@/assets/product/source/sort5.png'),
    imgBack: require('@/assets/product/source/sort-back5.png')
  },
  {
    name: '玩具手办',
    english: 'Plaything',
    img: require('@/assets/product/source/sort6.png'),
    imgBack: require('@/assets/product/source/sort-back6.png')
  },
]
</script>
<style lang='scss' scoped>
.title {
  padding: 94px 0 50px;
  font-size: 42px;
  color: #202020;
  text-align: center;
}
.introduce {
  position: relative;
  z-index: 10;
  padding: 0 60px 100px;
  border-radius: 6px;
  background: #fff;
  > p {
    font-size: 18px;
    line-height: 1.7em;
    color: #808080;
  }
}

.superiority {
  > ul {
    flex-wrap: wrap;
    > li {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      margin-bottom: 20px;
      padding: 35px 30px 60px;
      width: 285px;
      height: 360px;
      background-color: #fff;
      > h3 {
        margin: 29px 0 21px;
        width: 100%;
        font-size: 28px;
        font-weight: normal;
        text-align: center;
      }
      > img {
        height: 72px;
      }
      > p {
        font-size: 16px;
        line-height: 1.6em;
        color: #808080;
      }
    }
  }
}

.framework {
  margin-bottom: 100px;
  > ul {
    flex-wrap: wrap;
    > li {
      position: relative;
      margin-bottom: 15px;
      width: 390px;
      height: 390px;
      background-color: #fff;
      &:hover {
        .coverd {
          display: flex;
        }
      }
      .back-img {
        width: 100%;
        height: 100%;
      }
      .coverd {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: none;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        > img {
          height: 84px;
        }
        > h3,
        div {
          margin: 30px 0 27px;
          width: 100%;
          font-size: 28px;
          text-align: center;
        }
        > div {
          margin: 0;
          font-size: 20px;
        }
      }
    }
  }
}
</style>